import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
  // Link,
} from "react-router-dom";

import Portal from './portal.js';
import CustOrder from './pages/CustOrder.js';
import Login from './login.js';

import './portal.css';

class AuthRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    console.log(process.env.PUBLIC_URL);
    // console.log(this.props.location);
    // console.log(this.state.currPage);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/dev/portal">
            <Portal cust="Dev" />
          </Route>
          <Route path="/dev/order/:order">
            <CustOrder cust="Dev" />
          </Route>
          <Route path="/mgv/portal">
            <Portal cust="MGV" />
          </Route>
          <Route path="/mgv/order/:order">
            <CustOrder cust="MGV" />
          </Route>
          <Route path="/mgv">
            <Redirect from="/mgv" to="/mgv/login" />
            <Route path="/mgv/login">
              <Login cust="MGV" />
            </Route>
          </Route>
          <Route path="/">
            <Redirect from="/" to="/dev/login" />
            <Redirect from="/dev" to="/dev/login" />
            <Route path="/dev/login">
              <Login cust="Dev" />
            </Route>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default AuthRouter;
