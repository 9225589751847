import React, { Component } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment, Dimmer, Loader,
          // Modal, Confirm, Icon, Item
       } from 'semantic-ui-react';
import rtcLogo from './img/logo/rtclogo.svg';
import { Link } from "react-router-dom";
import './login.css';
// import axios from 'axios';

class LoginForm extends Component {
  
  state = {
    email: '',
    pass: '',
    newPass: '',
    confirmPass: '',
    token:'',
    emailOpen: false,
    passOpen: false,
    resetOpen: false,
    sentOpen: false,
    error: false,
    changeError: false,
    loading: false,
    ready: false,
    errorHead: '',
    errorMsg: '',
    emailError: false,
    passError: false,
  }
  
  changeHandler = (e) => { // handles input onChange to update state
    let name = e.target.name;
    let errorName = name + 'Error';
    this.setState({ [name]: e.target.value, [errorName]: false });
    // this.errorState(data.name, false);
  }
  
  login = () => { // call login api passing credentials
    localStorage.setItem('token', true);

    // const { email, pass } = this.state;
    
    // let emailErr = email == '';
    // let passErr = pass == '';
    
    // this.setState({ error: false, emailError: emailErr, passError: passErr, errorHead: '', errorMsg: '' });

    // if (emailErr || passErr) { // return if email or password is blank
    //   return;
    // }

    // let errorH = '', errorM = '';

    // this.setState({ loading: true });

    // let data = {
    //   "Email": email,
    //   "Password": pass,
    // }

    // const uri = 'https://magensa.rtcsupport.com/api/users/login';
    
    // try{
    //   (async () => {
    //     axios({
    //       data: data,
    //       method: 'post',
    //       mode: 'cors',
    //       timeout: 5000,
    //       url: uri
    //     })
    //     .then(
    //       res => {
    //         // console.log(res.data);
            
    //         if (res.data.Token != undefined) {
              
    //           localStorage.setItem('token', res.data.Token);
    //           localStorage.setItem('level', res.data.Level);
              
    //           if (res.data.Level == 0) {
    //             this.setState({ resetOpen: true });
    //             this.setState({ loading: false });
    //           }
    //           else {
    //             Router.push(endPoint + '/portal');
    //           }
    //         }
    //         else if (res.data.errors != undefined) { // server issue
    //           console.log(res.data.errors);
    //           errorH = 'Something Went Wrong';
    //           errorM = 'Please try again.';
    //           this.setState({ error: true, errorHead: errorH, errorMsg: errorM });
    //           this.setState({ loading: false });
    //         }
    //       }
    //     )
    //     .catch(
    //       error => { // catch error codes
    //         if (error && error.response) {
    //           console.error(error);
    //           if (error.response.data.code == 3) {
    //             errorH = 'Access Denied';
    //             errorM = 'Contact Help Desk.';
    //           }
    //           else if (error.response.data.code >= 0) {
    //             errorH = 'Unable to Login';
    //             errorM = 'Please check your credentials and try again.';
    //           }
    //         }
    //         else { // server issue
    //           errorH = 'Something Went Wrong';
    //           errorM = 'Please try again.';
    //         }
    //         this.setState({ error: true, errorHead: errorH, errorMsg: errorM, loading: false });
    //       }
    //     );
    //   })();
    // }
    // catch (error) {
    //   console.error(error);
    //   this.setState({ loading: false });
    // }
  }
  
  // handlePassSubmit = () => { // api call to update password
  //   const { pass, newPass, confirmPass } = this.state;
    
  //   if (newPass != confirmPass) { // new password has to match confirm password
  //     this.setState({ changeError: true });
  //     return;
  //   }
    
  //   let obj = {
  //     OldPassword: pass,
  //     NewPassword: newPass,
  //   }
    
  //   const token = localStorage.getItem('token');
  //   let uri = `https://magensa.rtcsupport.com/api/users/self`;
  //   axios({
  //     data: obj,
  //     method: 'put',
  //     mode: 'cors',
  //     timeout: 5000,
  //     url: uri,
  //     headers: { 'Authorization': token },
  //   })
  //   .then(
  //     res => {
  //       // console.log(res.data);
  //       this.setState({ pass: '', resetOpen: false });
  //     }
  //   )
  //   .catch(
  //     error => {
  //       console.error(error);
  //       // remove local storage before logout
  //       localStorage.clear();
  //     }
  //   );
  // }

  // forgot = () => {
  //   const { email, pass } = this.state;

  //   let emailErr = email == '';

  //   this.setState({ error: false, emailError: emailErr, errorHead: '', errorMsg: '' });

  //   if (emailErr) { // return if email or password is blank
  //     return;
  //   }

  //   let errorH = '', errorM = '';

  //   this.setState({ loading: true });
    
  //   let data = {
  //     "Email": email
  //   }

  //   const uri = 'https://magensa.rtcsupport.com/api/users/forgot';

  //   axios({
  //     data: data,
  //     method: 'post',
  //     mode: 'cors',
  //     timeout: 5000,
  //     url: uri
  //   })
  //   .then(
  //     res => {
  //       // console.log(res.data);
  //       this.setState({ emailOpen: false, loading: false, sentOpen: true });
  //     }
  //   )
  //   .catch(
  //     error => { // catch error codes
  //       if (error && error.response) {
  //         console.error(error);
  //       }
  //       else { // server issue
  //         errorH = 'Something Went Wrong';
  //         errorM = 'Please try again.';
  //       }
  //       this.setState({ error: true, errorHead: errorH, errorMsg: errorM, loading: false });
  //     }
  //   );
  // }

  // newPass = () => {
  //   const { email, token, newPass, confirmPass } = this.state;

  //   if (newPass != confirmPass) { // new password has to match confirm password
  //     this.setState({ changeError: true });
  //     return;
  //   }

  //   let obj = {
  //     Email: email,
  //     Password: newPass,
  //   }

  //   let uri = `https://magensa.rtcsupport.com/api/users/pass`;
  //   axios({
  //     data: obj,
  //     method: 'put',
  //     mode: 'cors',
  //     timeout: 5000,
  //     url: uri,
  //     headers: { 'Authorization': token },
  //   })
  //   .then(
  //     res => {
  //       // console.log(res.data);
  //       this.setState({ pass: '', passOpen: false });
  //       // URLSearchParams.delete('token');
  //       // URLSearchParams.delete('email');
  //     }
  //   )
  //   .catch(
  //     error => {
  //       if (error)
  //         console.error(error);
  //     }
  //   );
  // }

  handleClose = (e, data) => { // handles the modal closing
    e.preventDefault();
    this.setState({
      resetOpen: false,
      emailOpen: false,
      sentOpen: false,
      passOpen: false,
      emailError: false,
      passError: false,
    });
  }

  componentDidMount() {
    localStorage.clear();
    this.setState({ ready: true });

    // const params = new URLSearchParams(window.location.search);
    // const token = params.get('token');
    // const email = params.get('email');
    // if (token && email) {
    //   // console.log('received token & email');

    //   const uri = 'https://magensa.rtcsupport.com/api/token/ping';
    //   // console.log(token);

    //   axios.get(
    //     uri,
    //     { headers: { 'Authorization': token } }
    //   )
    //   .then(
    //     res => {
    //       // console.log("token ping:", res.data.msg);
    //       this.setState({ email: email, token: token, passOpen: true });
    //     }
    //   )
    //   .catch(
    //     error => {
    //       // console.error(error);
    //       this.setState({
    //         error: true,
    //         errorHead: 'Unable to Change Password',
    //         errorMsg: 'Session Expired',
    //       });
    //       window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    //     }
    //   );
    // }
  }
  
  render() {
    const { email, pass, error, emailError, passError, loading, ready, errorHead, errorMsg,
            // newPass, confirmPass, emailOpen, sentOpen, passOpen, resetOpen, changeError,
          } = this.state;
    
    let emailInp = <Form.Input
      autoFocus
      fluid
      icon='user'
      name='email'
      value={email}
      iconPosition='left'
      placeholder='Email'
      onChange={this.changeHandler}
      error={emailError} />
    
    // let newPassInp = <Form.Input
    //     fluid
    //     icon='lock'
    //     name='newPass'
    //     value={newPass}
    //     iconPosition='left'
    //     placeholder='Enter New Password'
    //     type='password'
    //     maxLength="16"
    //     onChange={this.changeHandler} />
      
    // let confPassInp = <Form.Input
    //     fluid
    //     icon='lock'
    //     name='confirmPass'
    //     value={confirmPass}
    //     iconPosition='left'
    //     placeholder='Confirm New Password'
    //     type='password'
    //     maxLength="16"
    //     onChange={this.changeHandler} />

    if (ready) {
      return (
        <div>
          
          {/* <Confirm
            open={emailOpen}
            header={<Header textAlign='center' size='large'>Enter Your Email Address:</Header>}
            content={<Form className='spaceAround'>{emailInp}</Form>}
            onCancel={this.handleClose}
            onConfirm={this.forgot}
            size='tiny'
            closeOnDimmerClick={false}
          />
          <Confirm
            open={sentOpen}
            content={
              <Message
                className='modalMessage'
                icon={<Icon name='mail' color='blue'/>}
                header='An email has been sent to your inbox.'
              />
            }
            onConfirm={this.handleClose}
            cancelButton={
              <Button disabled className='hidden'>Cancel</Button>
            }
            size='tiny'
            closeOnDimmerClick={false}
          />
          <Confirm
            open={passOpen}
            header={<Header textAlign='center' size='large'>Enter New Password:</Header>}
            content={
              <Form className='spaceAround'>
                {newPassInp}{confPassInp}
                <Message visible>Password must have a length of 6-16</Message>
                <Message negative hidden={!changeError}>
                  <Message.Header>Cannot Set New Password</Message.Header>
                  <p>Passwords do not match.</p>
                </Message>
              </Form>
            }
            onCancel={this.handleClose}
            onConfirm={this.newPass}
            cancelButton={
              <Button disabled className='hidden'>Cancel</Button>
            }
            confirmButton={
              <Button
                primary
                fluid
                size='large'
                disabled={newPass == '' || confirmPass == '' || newPass.length < 6 || newPass.length > 16}>
                Submit
              </Button>
            }
            size='tiny'
            closeOnDimmerClick={false}
          />
          <Confirm
            open={resetOpen}
            header={<Header textAlign="center" size='large'>Change Password:</Header>}
            content={
              <Form className='spaceAround'>
                {newPassInp}{confPassInp}
                <Message visible>Password must have a length of 6-16</Message>
                <Message negative hidden={!changeError}>
                  <Message.Header>Cannot Set New Password</Message.Header>
                  <p>Passwords do not match.</p>
                </Message>
              </Form>
            }
            onCancel={this.handleClose}
            onConfirm={this.handlePassSubmit}
            cancelButton={
              <Button disabled className='hidden'>Cancel</Button>
            }
            confirmButton={
              <Button
                primary
                fluid
                size='large'
                disabled={newPass == '' || confirmPass == '' || newPass.length < 6 || newPass.length > 16}>
                Submit
              </Button>
            }
            size='tiny'
            closeOnDimmerClick={false}
          /> */}
          
          <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
              <Form size='large'>
                <Segment>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Image src={ rtcLogo } size='small' />
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h2' color='blue' textAlign='center'>
                          {this.props.cust} Cust Order Portal Login
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  { emailInp }
                  <Form.Input
                    fluid
                    icon='lock'
                    name='pass'
                    value={ pass }
                    iconPosition='left'
                    placeholder='Password'
                    type='password'
                    maxLength="16"
                    onChange={ this.changeHandler }
                    error={passError} />
                  
                  <Link to={"/" + this.props.cust.toLowerCase()+"/portal"}>
                    <Button className='spaceBelow' onClick={ this.login } fluid size='large' primary>Login</Button>
                  </Link>
                  {/* <a href="javascript:void(0);" onClick={ () => { this.setState({ emailOpen: true }) } }>Forgot Password?</a> */}
                  <Message negative hidden={ !error }>
                    <Message.Header>{ errorHead }</Message.Header>
                    <p>{ errorMsg }</p>
                  </Message>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
          <Dimmer active={ loading } page>
            <Loader content='Loading' active />
          </Dimmer>
        </div>
      );
    }
    else {
      return(
        <Loader content='Loading' active />
      );
    }
  }
}

export default LoginForm;
