import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
// import { Link } from "react-router-dom";
import {
  Grid,
  // Segment,
  Dimmer,
  Loader,
  Container,
  Form,
  Input,
  Button,
  Icon,
  Table,
  Header,
  Message,
} from 'semantic-ui-react';
// import { isMobileOnly } from "react-device-detect";

import { uriPoint } from '../etc/config.js';

const itemHeaders = ["Status", "SKU", "Qty", "Unit Price", "Ext. Price", "Extra Shipping"];

let tHeadSize = 'tiny';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNum: props.orderNum || '',
      loading: false,
      message: false,

      custNum: '-',
      custName: '-',
      custAdd: '-',
      custAdd2: '-',
      custAdd3: '-',
      custCity: '-',
      custState: '-',
      custZip: '-',
      custPhone: '-',
      custEmail: '-',

      ordNum: '-',
      ordStatus: '-',
      ordDateTime: '- | -',
      ordDesc: '-',
      transId: '-',
      storeNum: '-',


      itemNum: '-',
      tenderNum: '-',

      transSubtotal: '-',
      transTax: '-',
      transShipping: '-',
      transTotal: '-',

      tenders: [
        {
          type: '-',
          amount: '-',
        }
      ],

      items: [
        {
          status: '-',
          sku: '-',
          qty: '-',
          unitPrice: '-',
          extPrice: '-',
          ship: '-',
        }
      ],
    };
  }

  // render headers for items table
  generateItemHeader = () => {
    // let spanHeads = ['Qty', 'Ext. Price', 'Shipping'];
    let spanHeads = ['Extra Shipping'];

    let headCells = [];

    itemHeaders.forEach(head => {
      let spanLen = (spanHeads.includes(head)) ? '2' : '1'
      headCells.push(<Table.HeaderCell key={head} colSpan={spanLen}>{head}</Table.HeaderCell>);
    });
    // itemHeaders.map((property, index) => {

    // }
    return (
      <Table.Header>
        < Table.Row key='itemHeader1' textAlign='center' >
          <Table.HeaderCell key="itemHeader" colSpan={itemHeaders.length + 3}>Items</Table.HeaderCell>
        </Table.Row >
        <Table.Row key='itemHeader2'>
          {headCells}
        </Table.Row>
      </Table.Header>
    );
  }

  // render rows for items table
  generateItems = () => {
    const { items } = this.state;

    let itemRows = [];

    items.forEach((item, index) => {
      itemRows.push(this.generateItem(item, index));
    });

    return (
      <Table.Body>
        {itemRows}
      </Table.Body>
    );
  }

  // render cells for items table
  generateItem = (obj, index) => { // generates a row in the table

    let key = 'item' + index;

    return ( // returns a row element
      <Table.Row key={key}>
        <Table.Cell key={key + "status"}>{obj.status}</Table.Cell>
        <Table.Cell key={key + "sku"}>{obj.sku}</Table.Cell>
        <Table.Cell key={key + "qty"} textAlign='right'>{obj.qty}</Table.Cell>
        <Table.Cell key={key + "unitPrice"}>{obj.unitPrice}</Table.Cell>
        <Table.Cell key={key + "extPrice"} textAlign='right'>{obj.extPrice}</Table.Cell>
        <Table.Cell key={key + "ship"} colSpan='2' textAlign='right'>{obj.ship}</Table.Cell>
      </Table.Row>
    )
  }

  // render footer for items table
  generateItemFooter = () => {
    const { itemNum, transSubtotal, transTax, transShipping, transTotal } = this.state;

    let spanSize = itemHeaders.length;

    return (
      <Table.Footer>
        <Table.Row key="transSums">
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              Items:
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              {itemNum}
            </Header>
          </Table.HeaderCell>
          {/* <Table.HeaderCell disabled></Table.HeaderCell> */}
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              Subtotal:
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              {transSubtotal}
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              Total Shipping:
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              {transShipping}
            </Header>
          </Table.HeaderCell>
        </Table.Row>
        {/* <Table.Row key="transSubtotal">
          <Table.HeaderCell colSpan={spanSize} textAlign='right'>
            <Header size={tHeadSize}>
              Subtotal
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell>{transSubtotal}</Table.HeaderCell>
        </Table.Row> */}
        <Table.Row key="transTax">
          <Table.HeaderCell colSpan={spanSize} textAlign='right'>
            <Header size={tHeadSize}>
              Tax:
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              {transTax}
            </Header>
          </Table.HeaderCell>
        </Table.Row>
        {/* <Table.Row key="transShipping">
          <Table.HeaderCell colSpan={spanSize} textAlign='right'>
            <Header size={tHeadSize}>
              Shipping
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell>{transShipping}</Table.HeaderCell>
        </Table.Row> */}
        <Table.Row key="transTotal">
          <Table.HeaderCell colSpan={spanSize} textAlign='right'>
            <Header size={tHeadSize}>
              Total:
            </Header>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            <Header size={tHeadSize}>
              {transTotal}
            </Header>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }

  // render rows for tender table
  generateTenders = () => {
    const { tenders } = this.state;
    let tendRows = [];

    tenders.forEach((tender, index) => {
      tendRows.push(this.generateTender(tender, index));
    });

    return (
      <Table.Body>
        {tendRows}
      </Table.Body>
    );
  }

  // render cells for tender table
  generateTender = (obj, index) => { // generates a row in the table

    let key = 'tender' + index;
    let cell = '', iconName = '';

    switch (obj.type) {
      case '1':
        cell = 'Cash';
        iconName = 'money';
        break;
      case '2':
        cell = 'Check';
        iconName = 'sticky note';
        break;
      case '3':
        cell = 'Visa';
        iconName = 'cc visa';
        break;
      case '4':
        cell = 'Master Card';
        iconName = 'cc mastercard';
        break;
      case '5':
        cell = 'American Express';
        iconName = 'cc amex';
        break;
      case '6':
        cell = 'Discover';
        iconName = 'cc discover';
        break;
      default:
        cell = 'Other';
        iconName = 'question circle';
    }

    // console.log(obj.type, cell, iconName);

    let icon = (iconName !== '') ? <Icon name={iconName} size='large' /> : '';

    return ( // returns a row element
      <Table.Row key={key}>
        <Table.Cell key={key + "type"}>{icon}{cell}</Table.Cell>
        <Table.Cell key={key + "amount"} textAlign='left'>{obj.amount}</Table.Cell>
      </Table.Row>
    )
  }

  handleChange = (e, { value }) => {
    this.setState({ orderNum: value })
  }

  searchOrder = () => {
    const { orderNum } = this.state;

    this.getOrder(orderNum);
  }

  getStatus = (status) => {
    switch (status) {
      case 'R':
        return 'Received';
      case 'X':
        return 'Processing';
      case 'U':
        return 'Partial Shipment';
      case 'C':
        return 'Fully Shipped';
      case 'D':
        return 'Cancelled/Deleted';
      default:
        return 'Other';
    }
  }

  getOrder = (orderNum) => {
    let cust = '', itemArray = [], tendersArray = [], headers = {}, details = [], tenders = [], itemCount = 0, subtotal = 0.00;

    this.setState({
      loading: true,
      message: false,
    });

    let uri = uriPoint + `api/controller`;

    axios({
      data: {
        'endPoint': 'orders/single',
        'method': 'post',
        'data': {
          "order": orderNum
        },
        'cust': this.props.cust.toLowerCase(),
      },
      method: 'post',
      mode: 'cors',
      timeout: 5000,
      url: uri,
    })
      .then(
        res => {
          console.log(res.data);
          headers = res.data[0].Header[0];
          details = res.data[0].Detail;
          tenders = res.data[0].Tender;

          cust = headers.CSCUST.trim();
          let dateTime = headers.CSISODAT + ' | ' + headers.CSISOTIM;
          dateTime = moment(dateTime, 'YYYY-MM-DD | HH.mm.ss').format('MM/DD/YY | hh:mm a') +  ' ' + headers.CSFZONE.trim();
          let status = this.getStatus(headers.CSOSTS);
          // this.setState({ orders: res.data });
          details.forEach(item => {
            let itemStatus = this.getStatus(item.CSDSTS);
            let sku = item.INUMBR;
            let qty = item.CSREQQT;
            itemCount += parseInt(qty);
            let unitPrice = (parseFloat(item.CSRETL)).toFixed(2);
            let extPrice = (unitPrice * qty).toFixed(2);
            subtotal += extPrice;
            let shipPrice = (parseFloat(item.CSTSHP)).toFixed(2);
            itemArray.push(
              {
                status: itemStatus,
                sku: sku,
                qty: qty,
                unitPrice: '$' + unitPrice,
                extPrice: '$' + extPrice,
                ship: '$' + shipPrice,
              }
            )
          })
          tenders.forEach(tend => {
            let tendType = tend.CSTTYP.trim();
            let tendAmt = '$' + (parseFloat(tend.CSTAMT)).toFixed(2);
            tendersArray.push(
              {
                type: tendType,
                amount: tendAmt,
              }
            )
          })
          this.getCust(cust);
          this.setState({
            custNum: cust,
            // custName: 'John Smith',
            // custAdd: '123 Main st',

            ordNum: orderNum,
            ordStatus: status,
            ordDateTime: dateTime,
            ordDesc: headers.CSDESC.trim(),
            transId: headers.CSPORTOR,
            storeNum: headers.CSSTR,

            itemNum: itemCount,
            tenderNum: tenders.length,

            transSubtotal: '$' + (parseFloat(headers.CSORDTOT) - parseFloat(headers.CSSHPTOT) - parseFloat(headers.CSTAXTOT)).toFixed(2),
            // transSubtotal: '$' + subtotal,
            transTax: '$' + (parseFloat(headers.CSTAXTOT)).toFixed(2),
            transShipping: '$' + (parseFloat(headers.CSSHPTOT)).toFixed(2),
            transTotal: '$' + (parseFloat(headers.CSORDTOT)).toFixed(2),

            items: itemArray,
            tenders: tendersArray,
          });
          // this.getCust('990123456789');
        }
      )
      .catch(
        error => {
          console.error(error);
          this.resetAll();
          this.setState({
            loading: false,
            message: true,
          });
        }
      );
  }

  getCust = (cust) => {
    let uri = uriPoint + `api/controller`;

    axios({
      data: {
        'endPoint': 'customers/id',
        'method': 'post',
        'data': {
          "number": cust
        },
        'cust': this.props.cust.toLowerCase(),
      },
      method: 'post',
      mode: 'cors',
      timeout: 5000,
      url: uri,
    })
      .then(
        res => {
          let info = res.data[0];
          // console.log(res.data);
          if (res.data.length === 0) {
            this.resetCust();
            this.setState({
              loading: false
            });
            return;
          }

          this.setState({
            // custNum: cust,
            custName: info.FIRSTNAME.trim() + ' ' + info.LASTNAME.trim(),
            custAdd: info.ADDRESS1.trim(),
            custAdd2: info.ADDRESS2.trim(),
            custAdd3: info.ADDRESS3.trim(),
            custCity: info.CITY.trim(),
            custState: info.STATE.trim(),
            custZip: info.ZIP.trim(),
            custPhone: info.PHONENUMBER1.trim(),
            custEmail: info.EMAIL1.trim(),
            loading: false,
          })
        }
      )
      .catch(
        error => {
          console.error(error);
          this.setState({ loading: false });
        }
      );
  }

  resetCust = () => {
    this.setState({
      custNum: '-',
      custName: '-',
      custAdd: '-',
      custAdd2: '-',
      custAdd3: '-',
      custCity: '-',
      custState: '-',
      custZip: '-',
      custPhone: '-',
      custEmail: '-',
    });
  }

  resetOrder = () => {
    this.setState({
      ordNum: '-',
      ordStatus: '-',
      ordDateTime: '- | -',
      ordDesc: '-',
      transId: '-',
      storeNum: '-',


      itemNum: '-',
      tenderNum: '-',

      transSubtotal: '-',
      transTax: '-',
      transShipping: '-',
      transTotal: '-',

      tenders: [
        {
          type: '-',
          amount: '-',
        }
      ],

      items: [
        {
          status: '-',
          sku: '-',
          qty: '-',
          unitPrice: '-',
          extPrice: '-',
          ship: '-',
        }
      ],
    });
  }

  resetAll = () => {
    this.resetCust();
    this.resetOrder();
  }

  componentDidMount = () => {
    const { orderNum } = this.state;

    if (orderNum !== '') {
      this.getOrder(orderNum);
    }
  }

  render() {
    const { orderNum, loading, message, custNum, custName, custAdd, custAdd2, custAdd3, custCity, custState, custZip, custPhone, custEmail, 
      ordNum, ordStatus, ordDateTime, ordDesc, transId, storeNum } = this.state;

    // let tHeadSize = (isMobileOnly) ? 'tiny' : 'small';

    // let ccIcon = (tendType !== '-') ? <Icon name={'cc ' + tendType.toLowerCase()} size='large' /> : '';

    let add2 = (custAdd2 === '' || custAdd2 === '-') ? '' : 
      <Table.Row key="custAdd2">
        <Table.Cell>
          <Header size={tHeadSize}>
            Address 2
          </Header>
        </Table.Cell>
        <Table.Cell>{custAdd2}</Table.Cell>
      </Table.Row>;
    let add3 = (custAdd3 === '' || custAdd3 === '-') ? '' : 
      <Table.Row key="custAdd3">
        <Table.Cell>
          <Header size={tHeadSize}>
            Address 3
          </Header>
        </Table.Cell>
        <Table.Cell>{custAdd3}</Table.Cell>
      </Table.Row>;

    return (
      <Grid textAlign='center'>
        <Grid.Column>
          <Container>
            <Form onSubmit={this.searchOrder}>
              <Form.Group widths='equal'>
                <Form.Field></Form.Field>
                <Form.Field>
                  <Input
                    placeholder='Order Number...'
                    // labelPosition='right'
                    value={orderNum}
                    onChange={this.handleChange}
                    action={<Button type='submit' color='blue'><Icon name='search' />Search</Button>}
                  />
                </Form.Field>
                <Form.Field></Form.Field>
              </Form.Group>
            </Form>
            <Message attached='bottom' error hidden={!message}>
              <Icon name='help' />
              Unable to find order.
            </Message>
            <Table celled singleLine padded color='blue' stackable compact>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell colSpan='2'>Customer Info</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key="custNum">
                  <Table.Cell width={4}>
                    <Header size={tHeadSize}>
                      Customer Number
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custNum}</Table.Cell>
                </Table.Row>
                <Table.Row key="custName">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Name
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custName}</Table.Cell>
                </Table.Row>
                <Table.Row key="custAdd">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Address
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custAdd}</Table.Cell>
                </Table.Row>
                {add2}
                {add3}
                <Table.Row key="custCity">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      City
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custCity}</Table.Cell>
                </Table.Row>
                <Table.Row key="custState">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      State
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custState}</Table.Cell>
                </Table.Row>
                <Table.Row key="custZip">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Zip Code
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custZip}</Table.Cell>
                </Table.Row>
                <Table.Row key="custPhone">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Phone
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custPhone}</Table.Cell>
                </Table.Row>
                <Table.Row key="custEmail">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Email
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{custEmail}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table celled singleLine padded color='blue' stackable compact>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell colSpan='2'>Order Info</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key="ordNum">
                  <Table.Cell width={4}>
                    <Header size={tHeadSize}>
                      Order Number
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{ordNum}</Table.Cell>
                </Table.Row>
                <Table.Row key="ordStatus">
                  <Table.Cell width={4}>
                    <Header size={tHeadSize}>
                      Order Status
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{ordStatus}</Table.Cell>
                </Table.Row>
                <Table.Row key="ordDateTime">
                  <Table.Cell width={4}>
                    <Header size={tHeadSize}>
                      Order Date | Time
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{ordDateTime}</Table.Cell>
                </Table.Row>
                <Table.Row key="ordDesc">
                  <Table.Cell width={4}>
                    <Header size={tHeadSize}>
                      Order Description
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{ordDesc}</Table.Cell>
                </Table.Row>
                <Table.Row key="transId">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      POS Trans ID
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{transId}</Table.Cell>
                </Table.Row>
                <Table.Row key="storeNum">
                  <Table.Cell>
                    <Header size={tHeadSize}>
                      Store Number
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{storeNum}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            {/* <Table
              celled
              singleLine
              padded
              striped
              // selectable
              // padded
              // collapsing={isMobileOnly}
              compact
              unstackable
              size="small"
              tableData={items}
              headerRows={itemHead}
              renderBodyRow={this.generateItem}
              color='blue'
            >
            </Table> */}
            <div style={{ width: "100%", overflow: "auto" }}>
              <Table
                celled
                singleLine
                padded
                striped
                // selectable
                // padded
                // collapsing={isMobileOnly}
                compact
                unstackable
                size="small"
                color='blue'
                textAlign='center'
              >
                {this.generateItemHeader()}
                {this.generateItems()}
                {this.generateItemFooter()}
              </Table>
            </div>

            <Table celled singleLine padded color='blue' unstackable compact textAlign='center'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Tender Info</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width='4'>Tender Type</Table.HeaderCell>
                  <Table.HeaderCell>Tender Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.generateTenders()}
            </Table>
          </Container>
          <Dimmer active={loading} page>
            <Loader content='Loading' active />
          </Dimmer>
        </Grid.Column>
      </Grid>
    )
  }
}

export default Order