import React, { Component } from 'react';
import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone';
import sort from 'fast-sort';
import {
  Grid,
  Segment,
  Container,
  Form,
  Button,
  Icon,
  Header,
  Table,
  Menu,
  Tab,
} from 'semantic-ui-react';
import {
  DateInput
} from 'semantic-ui-calendar-react';
import { isMobileOnly } from "react-device-detect";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import { uriPoint } from './etc/config.js';

import Order from './pages/order.js';

// options for status dropdown
const statusOptions = [
  { key: 'R', text: 'Received', value: 'R' },
  { key: 'X', text: 'Processing', value: 'X' },
  { key: 'U', text: 'Partial Shipment', value: 'U' },
  { key: 'C', text: 'Fully Shipped', value: 'C' },
  { key: 'D', text: 'Cancelled/Deleted', value: 'D' },
];

class Portal extends Component {
  constructor(props) {
    super(props);
    this.portalRef = React.createRef();
    this.orderRef = React.createRef();
    this.state = {
      activeTab: 0,
      column: null,
      direction: null,
      status: 'R',
      orderNum: '',
      custNum: '',
      orders: [
        {
          ORDERID: '-',
          ORDERSTATUS: '-',
          DATETIME: '-',
          CUSTOMER: '-',
          TRANID: '-',
        }
      ],
      startdate: moment(new Date()).format('MM/DD/YY'),
      enddate: moment(new Date()).format('MM/DD/YY'),
    };
  }

  // handles order selection from table
  handleRowClick = (value) => {
    this.setState({ orderNum: value });
    this.orderRef.click();
  }

  // handles status dropdown change
  handleStatusChange = (e, { value }) => {
    this.setState({ status: value });
    // this.searchByStatus(value);
  }

  // handles order # input change
  handleOrderChange = (e, { value }) => {
    this.setState({ orderNum: value });
  }

  // handles customer # input change
  handleCustChange = (e, { value }) => {
    this.setState({ custNum: value });
  }

  // handles tab change
  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeTab: activeIndex });
  }

  // handles sorting order list by headers
  handleSort = (clickedColumn) => () => { // handles the column sorting
    const { column, direction, orders } = this.state;

    // document.getElementsByClassName('tableContainer')[0].scroll(0, 0); // scrolls table to the top

    if (column !== clickedColumn || direction === 'ascending') { // sort descending
      this.setState({
        orders: this.sortThis(orders, clickedColumn, 'desc'),
        direction: 'descending',
      })
    }
    else if (direction === 'descending') { // sort ascending
      this.setState({
        orders: this.sortThis(orders, clickedColumn, 'asc'),
        direction: 'ascending',
      })
    }
    this.setState({ column: clickedColumn }); // set the column that was sorted

    // this.pageHelper(1);
  }

  // handles date selection change (start, end)
  dateHandler = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
      // this.errorState(name, false);
      if (name === 'startdate') {
        let start = moment.utc(value, 'MM/DD/YY');
        let end = moment.utc(this.state.enddate, 'MM/DD/YY');
        if (start.isAfter(end)) {
          this.setState({ enddate: value });
        }
      }
    }
    //this.changeHandler;
  }

  // sort object by column (ascending, descending)
  sortThis = (obj, col, dir) => {
    if (dir === 'desc') {
      return sort(obj).desc(u => u[col] * 1 || u[col]);
    }
    else if (dir === 'asc') {
      return sort(obj).asc(u => u[col] * 1 || u[col]);
    }
    else {
      return obj;
    }
  }

  // render order rows for table
  generateOrders = () => {
    const { orders } = this.state;

    let orderRows = [];

    orders.forEach((order, index) => {
      orderRows.push(this.generateOrder(order, index));
    });

    return (
      <Table.Body>
        {orderRows}
      </Table.Body>
    );
  }

  // render order cells for table
  generateOrder = (obj, index) => { // generates a row in the table

    let key = 'order' + index;
    // let dateTime = (obj.DATETIME !== '-') ? moment(obj.DATETIME, 'YYYY-MM-DD | HH.mm.ss').format('MM/DD/YY | hh:mm a') : '-';

    return ( // returns a row element
      <Table.Row key={key} onClick={() => this.handleRowClick(obj.ORDERID)}>
        <Table.Cell key={key + "orderid"}>{obj.ORDERID}</Table.Cell>
        <Table.Cell key={key + "orderstatus"}>{obj.ORDERSTATUS}</Table.Cell>
        <Table.Cell key={key + "orderdatetime"}>{obj.DATETIME}</Table.Cell>
        <Table.Cell key={key + "customer"}>{obj.CUSTOMER}</Table.Cell>
        <Table.Cell key={key + "tranid"}>{obj.TRANID}</Table.Cell>
      </Table.Row>
    )
  }

  // return full status description from letter
  getStatus = (status) => {
    switch (status) {
      case 'R':
        return 'Received';
      case 'X':
        return 'Processing';
      case 'U':
        return 'Partial Shipment';
      case 'C':
        return 'Fully Shipped';
      case 'D':
        return 'Cancelled/Deleted';
      default:
        return 'Other';
    }
  }

  // call getOrders by status value
  searchByStatus = (value) => {
    const { status } = this.state;
    let stat = (value) ? value : status;
    let data = {
      'status': stat
    }
    this.getOrders('status', data);
  }

  // call getOrders by customer # value
  searchByCust = () => {
    const { custNum } = this.state;
    let data = {
      'customer': custNum
    }
    this.getOrders('customer', data);
  }

  // call getOrders by date range values
  searchByDates = () => {
    const { startdate, enddate } = this.state;

    let start = moment(startdate, 'MM/DD/YY').format('YYYY-MM-DD');
    let end = moment(enddate, 'MM/DD/YY | HH.mm.ss').format('YYYY-MM-DD');
    // console.log(dateRange);
    let data = {
      'startdate': start,
      'enddate': end
    }
    this.getOrders('dates', data);
  }

  // make api call with given end point and data to receive order list
  getOrders = (point, data) => {
    let uri = uriPoint + `api/controller`;
    // console.log('/cust/', this.props.cust.toLowerCase());
    axios({
      data: {
        'endPoint': 'orders/' + point,
        'method': 'post',
        'data': data,
        'cust': this.props.cust.toLowerCase(),
      },
      method: 'post',
      mode: 'cors',
      timeout: 5000,
      url: uri,
    })
      .then(
        res => {
          // console.log(res.data);
          let orders = [];
          res.data.forEach(order => {
            let tz = order.TIMEZONE.trim();
            // let offset = moment.tz(tz).format('Z');
            // console.log(offset);
            let datetime = order.ORDERDATE + ' | ' + order.ORDERTIME;
            // if (moment(datetime, 'YYYY-MM-DD | HH.mm.ss Z').isDST()) {
              
            // }
            // let utc = datetime + ' ' + offset;
            let displayDateTime = moment(datetime, 'YYYY-MM-DD | HH.mm.ss').format('MM/DD/YY | hh:mm A') + ' ' + tz;
            // let utcDateTime = moment.utc(utc, 'YYYY-MM-DD | HH.mm.ss Z').format('YYYY-MM-DD HH:mm');
            orders.push({
              ORDERID: order.ORDERID,
              ORDERSTATUS: this.getStatus(order.ORDERSTATUS),
              DATETIME: displayDateTime,
              // UTCTIME: utcDateTime,
              CUSTOMER: order.CUSTOMER,
              TRANID: order.TRANID,
            });
          });
          orders = this.sortThis(orders, 'ORDERID', 'desc');
          console.log(orders);
          this.setState({ orders: orders });
        }
      )
      .catch(
        error => {
          console.error(error);
        }
      );
  }

  componentDidMount = () => {
    this.searchByStatus();
  }

  render() {
    const { activeTab, status, orderNum, custNum, column, direction, startdate, enddate } = this.state;

    const panes = [
      // { menuItem: {key: 'header', content: 'Search By', header: true, disabled: true}},
      { menuItem: 'Status', render: () =>
        <Form onSubmit={() => this.searchByStatus()}>
          <Form.Dropdown
            label='Status'
            options={statusOptions}
            value={status}
            onChange={this.handleStatusChange}
            selection
          />
          <Form.Button type='submit' primary><Icon name='search' />Search</Form.Button>
        </Form> },
      { menuItem: 'Date Range', render: () =>
        <Form onSubmit={() => this.searchByDates()}>
          <Form.Group widths="equal">
            <Form.Field>
              <DateInput
                name="startdate"
                placeholder="Starting Date"
                value={startdate}
                iconPosition="left"
                popupPosition="top center"
                dateFormat="MM/DD/YY"
                minDate="01/01/19"
                onChange={this.dateHandler}
                closable
                maxDate={new Date()}
                label="Starting Date"
                // error={errors.startdate}
                hideMobileKeyboard
                animation='none'
                closeOnMouseLeave={false}
              />
            </Form.Field>
            <Form.Field>
              <DateInput
                name="enddate"
                placeholder="Ending Date"
                value={enddate}
                iconPosition="left"
                popupPosition="top center"
                dateFormat="MM/DD/YY"
                minDate={startdate}
                onChange={this.dateHandler}
                closable
                maxDate={new Date()}
                label="Ending Date"
                // error={errors.enddate}
                hideMobileKeyboard
                animation='none'
                closeOnMouseLeave={false}
              />
            </Form.Field>
          </Form.Group>
          <Form.Button type='submit' primary><Icon name='search' />Search</Form.Button>
        </Form> },
      { menuItem: 'Customer', render: () => 
        <Form onSubmit={() => this.searchByCust()}>
          <Form.Input
            label='Customer #'
            placeholder='Customer Number...'
            value={custNum}
            onChange={this.handleCustChange}
          />
          <Button type='submit' color='blue'><Icon name='search' />Search</Button>
        </Form>
      },
      { menuItem: 'Order #', render: () => 
        <Form onSubmit={() => this.orderRef.click()}>
          <Form.Input
            label='Order #'
            placeholder='Order Number...'
            value={orderNum}
            onChange={this.handleOrderChange}
          />
          <Button type='submit' color='blue'><Icon name='search' />Search</Button>
        </Form>
      },
    ]

    return (
      <Router basename={"/" + this.props.cust.toLowerCase() + "/portal"}>
        <Link to="/" ref={(ref) => { this.portalRef = ref }} />
        <Link to="/order" ref={(ref) => { this.orderRef = ref }} />
        <div className='wrapper'>
          <Segment className={(isMobileOnly) ? 'pageContent mobile' : 'pageContent'}>
            <Menu
              // fixed={fixed ? 'top' : null}
              inverted
              attached='top'
              size='large'
              widths='3'
              color='blue'
              style={{ margin: '0em' }}
            >
              <Menu.Item as='a' onClick={() => this.portalRef.click()}>
                <Icon name='th list' />Orders
              </Menu.Item>
              <Menu.Item>
                <Header size={(isMobileOnly) ? 'small' : 'large'} inverted textAlign='center'>{this.props.cust} Customer Order Portal</Header>
              </Menu.Item>
              <Menu.Item as='a' onClick={() => this.orderRef.click()}>
                <Icon name='search' />Search Order
              </Menu.Item>
            </Menu>

            <Switch>
              <Route path="/order">
                <Header size={(isMobileOnly) ? 'large' : 'huge'} color='blue' textAlign='center'>Order Details</Header>
                <Segment style={{ padding: '1em 1em' }} vertical>
                  <Order orderNum={orderNum} cust={this.props.cust} />
                </Segment>
              </Route>
              <Route path="/">

                <Header size={(isMobileOnly) ? 'large' : 'huge'} color='blue' textAlign='center'>Order List</Header>
                <Segment style={{ padding: '1em 1em' }} vertical>
                  <Grid textAlign='center'>
                    <Grid.Column>
                      <Container>
                        <Tab
                          // defaultActiveIndex='1'
                          activeIndex={activeTab}
                          onTabChange={this.handleTabChange}
                          menu={{ fluid: true, tabular: !isMobileOnly, stackable: true }}
                          panes={panes}
                        />
                        <br />
                        <div style={{ width:"100%", overflow: "auto" }}>
                          <Table 
                            celled
                            singleLine
                            padded
                            striped
                            selectable
                            sortable
                            // padded
                            // collapsing={isMobileOnly}
                            compact='very'
                            unstackable
                            size="small"
                            color='blue'
                            textAlign='center'
                          >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell
                                  sorted={column === 'ORDERID' ? direction : null}
                                  onClick={this.handleSort('ORDERID')}
                                >
                                  Order #
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === 'ORDERSTATUS' ? direction : null}
                                  onClick={this.handleSort('ORDERSTATUS')}
                                >
                                  Status
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === 'DATETIME' ? direction : null}
                                  onClick={this.handleSort('DATETIME')}
                                >
                                  Date | Time
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === 'CUSTOMER' ? direction : null}
                                  onClick={this.handleSort('CUSTOMER')}
                                >
                                  Customer ID
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === 'TRANID' ? direction : null}
                                  onClick={this.handleSort('TRANID')}
                                >
                                  Transaction ID
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            {this.generateOrders()}
                          </Table>
                        </div>
                      </Container>
                    </Grid.Column>
                  </Grid>
                </Segment>

              </Route>
            </Switch>

          </Segment>
        </div>
      </Router>
    )
  }
}

export default Portal